@import "themes/default/globals/site.variables";
@import "site/globals/site.variables";

html.mobile-layout {
    font-size: 8px;
}

.mobile-layout {
    body {
        margin-top: 15rem;
    }

    body.simple-header {
        margin-top: 6rem;
    }

    .page-title {
        font-size: 2.5rem;
        padding-top: 3rem;
        padding-bottom: 1rem;
        line-height: 3rem;
        height: 7rem;
    }

    .page-title-nice {
        font-size: 3.25rem;
    }

    .ui.container.fluid {
        margin-left: 0 !important;
        margin-right: 0 !important;
    }

    #page-header {
        z-index: 9999;
        position: fixed;
        top: 0;
        width: 100%;
    }

    .ui.header.tyreprint-title {
        font-size: 3rem;
        line-height: 3rem;
        height: 3rem;

        .text {
            line-height: 3rem;
        }
    }

    .header-center {
        max-height: none;

        #banners-header-line {
            .container {
                height: auto;
            }
        }

        >.container {
            margin: 0 !important;
            height: auto;

            .header-top {
                height: 3rem;
            }

            >.main {
                max-height: 6rem;
                height: 6rem;

                >.row {
                    flex-wrap: nowrap;
                    justify-content: space-between;
                }

                .logo-wrapper,
                #user-menu-wrapper {
                    display: flex;
                    align-items: center;
                    flex-wrap: nowrap;
                }

                .logo-wrapper {
                    flex: 1 1 auto;

                    >#logo-image {
                        max-width: 80%;
                    }
                }

                #user-menu-wrapper {
                    >#user-menu {
                        >.grid {
                            display: flex;
                            flex-wrap: nowrap;
                        }
                    }
                }
            }
        }

        .main-menu-button-wrapper {
            height: 6rem;

            .button {
                margin: 0;
                height: 6rem;
                width: 6rem;
            }
        }

        .company-navbar {
            background-color: @white;

            .dropdown.button {
                padding: 1rem 1rem 0;
                line-height: 3rem;
                font-size: 2rem;
                margin-right: 0;

                &.active {
                    background-color: @marso-dark-blue;
                    color: @white;
                }
            }

            .menu {
                position: absolute;
                right: 0;
                background-color: @marso-blue;
                z-index: 999;
                width: 70%;
                padding: 2rem 0rem;

                .item {
                    display: block;
                    font-size: 2rem;
                    padding: 0.5rem 2rem;
                    color: @white;
                }
            }
        }
    }

    #user-menu>.grid>.item {
        padding: 0;
        max-width: 5rem;
        min-width: 5rem;

        .menu-content-wrapper {
            max-height: 6rem;
            padding: 1.5rem 0;

            .icon {
                font-size: 3rem;
                line-height: 3rem;
                height: 3rem;
            }

            .user-menu-text {
                display: none;
            }
        }

        &:last-child {
            .menu-content-wrapper {
                border-right: none;
            }
        }
    }

    .mobile-search-box {
        #search-box {
            background-color: @marso-gray-20;
            padding: 1rem;
            max-height: 6rem;
            min-height: 6rem;
            position: relative;

            &:after {
                display: none;
            }

            #free-hand-search-wrapper {
                line-height: 4rem;
                border-radius: 5px;
                box-shadow: none;

                #free-hand-search-tags {
                    min-width: 1rem;
                    border-radius: 5px 0 0 5px;
                    line-height: 4rem;

                    .ui.label.tag {
                        padding: 0.5rem;
                        line-height: 1rem;

                        &:before {
                            border-width: 1rem 1rem 1rem 0;
                            left: -1rem;
                        }

                        &:after {
                            font-size: 0.5rem;
                        }
                    }
                }

                #free-hand-search {
                    border-radius: 0 5px 5px 0;
                }
            }

            #free-hand-search-button {
                line-height: 4rem;
                height: 4rem;
                background-color: @marso-gray-20;
                box-shadow: none;
                border-radius: 5px;

                .icon {
                    font-size: 2.5rem;
                    color: @white;
                }
            }
        }
    }

    .main-menu-button-wrapper {
        #main-menu-button {
            padding: 0 !important;
        }
    }

    #footer-top {
        padding: 0 !important;
    }

    #footer-center {
        padding-top: 1rem;
        padding-bottom: 1rem;

        .menu {
            justify-content: center;
            line-height: 1rem;
            min-height: 1.2rem;

            .item {
                max-height: 2rem;
            }
        }
    }

    @banner-home-slider-height: (450vw/10.24);

    #banners-home-slider {
        height: @banner-home-slider-height;

        .banner {
            img {
                height: @banner-home-slider-height;
            }

            .text {

                .header,
                .subheader,
                .description,
                .button {
                    margin: 0 (@banner-home-slider-height / 15) (@banner-home-slider-height / 15) (@banner-home-slider-height / 15);
                }

                .header {
                    min-height: @banner-home-slider-height / 7;
                    font-size: @banner-home-slider-height / 7;
                }

                .subheader {
                    min-height: @banner-home-slider-height / 13;
                    font-size: @banner-home-slider-height / 13;
                    line-height: @banner-home-slider-height / 13;
                }

                .description {
                    min-height: @banner-home-slider-height / 15;
                    font-size: @banner-home-slider-height / 15;
                    line-height: @banner-home-slider-height / 15;
                }
            }
        }

        .control-panel {
            .next-button {
                position: absolute;
                bottom: @banner-home-slider-height/2 - 4.8rem - 2.3rem;
                right: -2rem;
            }

            .previous-button {
                position: absolute;
                bottom: @banner-home-slider-height/2 - 4.8rem - 2.3rem;
                left: -2rem;
            }

            .direct-buttons {
                padding-bottom: 1.5rem;

                .button {
                    margin-left: 1rem;
                    margin-right: 1rem;
                    padding: 0.75rem;
                }
            }
        }
    }

    #banners-home-secondary {
        max-width: 300px;
    }

    .products-categories-box {
        padding-bottom: 4rem;

        .ui.vertical.menu {
            padding-top: 2rem;

            .item {
                margin: 0;
                padding: 1rem 4rem;
                height: 4rem;
                font-size: 1.8rem;
                line-height: 2rem;

                .icon {
                    float: right !important;
                }
            }

            .item.odd {
                background-color: @marso-gray-06;
            }

            .item.even {
                background-color: @marso-gray-10;
            }
        }
    }

    .highlighted-offers-panel {

        .highlighted-offers,
        .timed-offers {
            .header {
                margin-bottom: 3rem;
            }

            .hs-frame {
                background-color: @white;
            }

            .products {
                padding-top: 0;

                .product {
                    max-width: 30rem;
                }

                .product:hover {
                    //z-index: auto !important;
                }
            }
        }
    }

    .product-descriptions {
        padding-top: 3rem;
    }

    .accordion-title {
        cursor: pointer;
        font-weight: normal;
        padding: 1rem 2rem !important;
        margin: 0 0 0.5rem 0;
        background-color: @marso-gray-80;
        color: @white  !important;
        height: 5rem;

        .text {
            padding: 0 0 0 0.5rem;
            font-size: 1.75rem;
            line-height: 3rem;
            height: 3rem;
        }

        .helper-icon {
            float: right;
            height: 1rem;
            padding: 1rem 0;
        }

        .type-icon {
            float: left;
            font-size: 2.75rem;
        }

        &.active {
            background-color: @marso-red  !important;
        }
    }

    .accordion-content {
        will-change: height;
        transition: height 0.5s;
        padding: 0 2rem !important;
        overflow: auto;
        font-size: 1.75rem;

        &.active {
            height: auto;
        }

        .inner {
            padding-top: 2rem;
            padding-bottom: 2rem;
        }
    }

    .accordion-content {
        .content-news.teaser.card {
            .content {
                font-size: 1.5rem;
                line-height: 1.75rem;
            }

            .title {
                h2 {
                    font-size: 2.25rem;
                }
            }
        }
    }

    .eu-tyre-label-container {
        .tyre-label-image {
            margin: auto;
            float: none;
        }

        .tyre-label-content {
            margin: 2rem 0 0 0;
        }
    }

    #service-site {
        .contact-informations {
            padding-top: 0;

            .contact-informations-list {
                padding-right: 0;
                font-size: 1.75rem;

                .item {
                    line-height: 3rem;
                    padding: 1rem 0;
                }
            }
        }

        #available-services {
            padding-top: 4rem;

            header {
                padding-bottom: 1rem;
            }

            .service-type-block {
                margin-top: 1rem !important;
                border-radius: 0;

                .service-type-icon.column {
                    background-color: @marso-gray-80;
                    color: @white;
                    border-right: none;
                    border-bottom: 2px solid @marso-red;
                    border-radius: 0;
                    min-height: 5rem;
                }

                .service-type-title.column {
                    background-color: @marso-gray-80;
                    color: @white;
                    border-bottom: 2px solid @marso-red;
                    min-height: 5rem;

                    .header {
                        font-size: 1.75rem;
                        line-height: 3rem;
                        padding: 1rem 0 0.5rem;

                    }
                }

                .service-type-services {
                    padding-top: 2rem !important;
                    padding-bottom: 1rem !important;

                    .service {
                        padding: 0 1rem 1rem 1rem !important;

                        a {
                            padding: 1rem 1rem;
                        }
                    }
                }
            }
        }

        .service-site-description {
            font-size: 1.75rem;
        }
    }

    #add-to-cart-modal {
        .header {
            text-transform: uppercase;
            padding: 2rem 1rem 1rem;
            font-size: 1.75rem;
        }

        .product-price-wrapper {
            font-size: 2rem;
        }

        .footer {
            .question-box {
                height: auto;
                display: block;
                .image {
                    height: 9rem;
                    line-height: 9rem;
                    width: 9rem;

                    .icon {
                        font-size: 5rem;
                        line-height: 9rem;
                    }
                }

                .yes-or-no {
                    position: static;
                    right: auto;
                    top: auto;
                    text-align: center;
                    height: 3rem;
                    background-color: @marso-gray-10;
                    border-bottom-right-radius: 0.5rem;
                    margin-left: 4rem;
                    min-width: auto;

                    .button {
                        font-size: 1.5rem;
                        padding: 0.5rem 1rem;
                    }
                }

                .question {
                    margin-left: 4rem;
                    height: 6rem;
                    font-size: 1.5rem;
                    padding: 1rem 2rem 1rem 6rem;
                    border-bottom-right-radius: 0;

                }
            }

            .goto-cart-url {
                font-size: 1.25rem;
            }
        }
    }

    #service-booking {
        #booking-choose-garage {
            >.header>.grid>.column:first-child {
                font-size: 1.6rem;
            }

            .garage {
                max-width: 25rem;
                margin: 1rem;

                .name {
                    line-height: 1.8rem;

                    .distance {
                        font-size: 1.4rem;
                    }
                }

                .extras {
                    line-height: 1.5rem;
                    font-size: 1.4rem;

                    strong {
                        line-height: 1rem;
                        font-size: 1.2rem;
                    }
                }

                .selected-button {
                    .choose-button {
                        font-size: 1.3rem;
                    }
                }
            }
        }

        #booking-choose-datetime {
            #date-selector {
                .header {
                    height: 6rem;
                    padding: 1rem;
                    line-height: 4rem;
                    font-size: 1.5rem;
                    border: none;
                    margin: 0;

                    .button {
                        font-size: 2rem;
                        padding: 1.2rem 1rem 1rem;
                        height: 4rem;
                    }
                }

                .days {
                    .day {
                        button {
                            font-size: 1.2rem;
                            line-height: 1.2em;
                        }
                    }
                }
            }

            #booking-choose-service {
                .service {
                    max-width: 25rem;
                    margin: 1rem;
                }
            }

            #time-selector {
                .time {
                    .field {
                        padding: 1rem;

                        .date-value {
                            padding-top: 0.5rem;
                            font-size: 2rem;
                            line-height: 2rem;
                        }

                        .time-value {
                            font-size: 2.5rem;
                            line-height: 3rem;
                        }
                    }
                }
            }

            .datetimes-explanation {
                font-size: 1.4rem;

                .column:first-child {
                    font-size: 1.7rem;
                }
            }
        }

        #booking-preview {
            border-radius: 0;

            .list {
                margin: 1rem;

                .item:last-child {
                    padding-bottom: 2rem;
                }

                .item {
                    font-size: 2rem;

                    >.icon {
                        font-size: 3rem;
                    }
                }
            }
        }

        #booking-button-block {
            background: none;

            #booking-button {
                min-height: 4rem;
                text-align: left;
                font-size: 2rem;
                padding: 2rem 6rem 2rem 2rem !important;

                .icon {
                    width: 6rem;
                }
            }
        }
    }

    #cart {
        .page-title {
            padding: 2rem 3rem 1rem 3rem;
        }

        .cart-products {
            .segment {
                border-top: 2px solid @marso-gray-50;
                border-bottom: none;
            }

            .product {
                padding: 0;

                .first-block {
                    padding: 0 2rem;
                    background-color: @white;
                }

                .second-block {
                    padding: 0 2rem;
                }

                .product-price-wrapper {
                    font-size: 2rem;
                    padding: 0;

                    .product-price {
                        padding: 0;
                    }

                    .quantity-form {
                        padding: 0;
                        margin: 0;
                    }
                }

                .remove-button {
                    z-index: 1;
                    position: relative;
                }
            }

            .product.service {
                .product-price-wrapper {
                    padding: 0;

                }
            }

            .question-box {
                padding: 0 2rem;

                .question {
                    margin: 0;
                    font-size: 1.5rem;
                    line-height: 2rem;
                    height: auto;
                    padding-right: 14rem;
                    padding-bottom: 1.5rem;
                }

                .yes-or-no {
                    width: 12rem;
                    min-width: auto;

                    .radio-field {
                        .radio-label {
                            .ui.labeled.button {

                                .label,
                                .ui.button {
                                    font-size: 1.5rem;
                                }
                            }
                        }
                    }
                }
            }

            .warning-box {
                padding: 0 2rem;
                .warning {
                    margin-left: 0;
                }
                .image {
                    line-height: 5rem;
                    height: 5rem;

                    .icon {
                        height: 5rem;
                        font-size: 4.5rem;
                        line-height: 5rem;
                        text-align: center;
                    }
                }
            }
        }

        .sidebar {
            padding: 3rem;

            .order-summary.box {

                padding: 2rem;
            }

            .sidebar-button {}
        }
    }

    .page-cart {

        .services-box,
        .cetelem-box {
            padding: 3rem 3rem;

            >.header {
                font-size: 2rem;
            }

            .content-service.teaser {
                >.image {

                    .name {
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                    }
                }

                .description-block {
                    .price {
                        font-size: 1.75rem;
                    }
                }
            }
        }

        .cetelem-box {
            #cetelem-calculator {
                display: block;
                text-align: center;
            }
        }
    }

    #checkout {
        .page-title {
            padding: 2rem 3rem 1rem 3rem;
        }

        .sidebar {
            padding: 0 3rem;
        }

        #shipping-method-form {
            .shipping-method-item {
                .checkbox-field {
                    .checkbox-label {
                        height: auto;
                    }
                }
            }
        }
    }

    #checkout-summary {
        .page-title {
            padding: 2rem 3rem 1rem 3rem;
        }

        .sidebar {
            padding: 0 3rem 3rem;
        }
    }

    #thank-you-page {
        .important-header {
            .text {
                top: 12rem;
                font-size: 4rem;
                line-height: 4rem;

                &:after {
                    height: 5rem;
                    top: 0;
                }
            }
        }

        .info-block {
            font-size: 1.75rem;

            .icon {
                font-size: 4rem !important;
            }
        }
    }

    .main-menu-box {
        .store-menu {
            padding: 0.5rem;

            .item {
                img {
                    max-width: 12rem;
                }
            }
        }

        .panel-left {
            z-index: 101;
        }

        .right-subbox {
            .content-wrapper {
                padding: 1rem;

                .menu {
                    padding-bottom: 1rem;

                    .item {
                        font-size: 1.75rem;
                        line-height: 2rem;
                    }

                    .item.main {
                        font-size: 1.5rem;
                        line-height: 1.5rem;
                    }
                }
            }
        }
    }

    .detailed-search-panel {
        position: relative;

        .pager-wrapper.results-line {
            >.column.pager-outside {
                background: none;
            }
        }

        .results-panel {
            border-left: none;
            padding-left: 1rem !important;

            .results-line {
                .show-filter-panel {
                    padding: 0.5rem 1rem;
                }
            }
        }

        .floated-form-panel {
            min-height: 80rem;
            will-change: left;
            transition: left 0.5s ease-out;
            background-color: @white;
            position: absolute;
            z-index: 10;
            left: -80%;
            top: 0;
            width: 80%;
            box-shadow: 0px 4px 5px 0px fade(@marso-gray-85, 30%), 0px 4px 5px 0px fade(@white, 30%);

            .form-panel {
                padding: 1.5rem;

                .button[type=submit] {
                    visibility: hidden;
                }
            }
        }

        .floated-form-panel.active {
            left: 0;
        }

        .search-status {
            bottom: 2rem !important;
            width: 60%;
            position: fixed !important;
            z-index: 99;
            max-height: 5rem;

            .button {
                max-height: 5rem;
            }

            .opened {
                position: relative;
                left: -100%;
                transition: left 0.5s ease-out;
                bottom: 5rem;
            }

            .closed {
                position: relative;
                left: -0.5rem;
                transition: left 0.5s ease-out;
                bottom: 0;
            }

            &.open {
                .opened {
                    left: -0.5rem;
                }

                .closed {
                    left: -100%;
                }
            }
        }
    }

    .product-search-forms-accordion {
        .accordion-content {
            .ui.form {
                padding: 1rem 0 2rem;

                .sizes {
                    padding-bottom: 0;

                    .search.dropdown {
                        padding: 0.5rem;
                        line-height: 2rem;
                        min-height: auto;

                        .icon {
                            padding: 0.5rem;
                        }

                        .search {
                            padding: 0.5rem;
                        }
                    }
                }
            }
        }
    }
}

html.mobile-layout {
    .discount-page-landing {
        background-size: 245%;
        background-position: center 6rem;
    }

    .content-discount {
        .detailed-search-panel {
            padding: 0 !important;
        }

        .content-panel {
            .title {
                font-size: 3rem;
                line-height: 3.5rem;
            }

            .faq-list {
                padding: 0 0 1rem;

                .question {
                    padding: 1rem 2rem;
                }

                .answer {
                    padding: 0 2rem 1rem;
                }
            }

            .description-wrapper .description {
                padding: 0 2rem 2rem;
            }
        }
    }

    .content-discount.landing-page {
        .landing-header {
            min-height: 85vw;

            .landing-page-title-placeholder {
                min-height: 32rem;
            }

            .landing-page-title {
                padding: 2rem;
                font-size: 3rem;
                font-weight: bold;
                color: @white;
                line-height: 1.5em;
                text-shadow: 5px 1px 0px fade(@marso-gray-85, 95%),
                    -1px -1px 5px fade(@marso-gray-85, 90%);

                .center-title {
                    font-size: 4rem;
                    line-height: 1.6em;
                }
            }

            .highlight-box {
                .text-panel {
                    box-shadow: 0px 0px 10px 5px fade(@marso-gray-80, 50%);
                    border-radius: 0;
                    padding: 3rem 2rem;
                    margin-top: 8rem;

                    .description-text {
                        font-size: 2rem;
                        line-height: 2.5rem;

                        strong {
                            display: block;
                        }
                    }
                }
            }

            .highlight-box.with-image {
                .text-panel {
                    margin-left: 0;
                    margin-top: 4rem;
                }

                .image-panel {
                    float: none;
                    min-height: 3rem;
                    min-width: 100%;

                    // add a white circle below the image
                    //&:before {
                    //  content: "";
                    //  background-color: @white;
                    //  position: absolute;
                    //  top: 2rem;
                    //  left: 50%;
                    //  margin-left: -4rem;
                    //  width: 8rem;
                    //  height: 8rem;
                    //  border-radius: 50%;
                    //  box-shadow: 0px 0px 10px 5px fade(@marso-gray-80, 50%);
                    //}
                    .image {
                        top: 2rem;
                        left: 50%;
                        margin-left: -4rem;
                        width: 8rem;
                    }
                }
            }
        }

        .content-panel {
            border-radius: 0;
            padding: 0;

            .goto-down>.icons {
                top: -4rem;
            }

            //.faq-list {
            //  .question {
            //    padding-bottom: 0.5rem;
            //  }
            //  .answer {
            //    padding: 0 1rem 2rem;
            //  }
            //}
        }
    }

    .discounts-contents {
        .discounts-title {
            font-size: 4rem;
        }

        .quicklinks {
            .quicklinks-menu {
                a {
                    font-size: 1.75rem;
                }
            }
        }
    }

    #discounts-top-sizes-section {
        .size-link {
            font-weight: normal;
            color: @marso-gray-80;
            font-size: 1.75rem;
        }

        .size-label {
            font-weight: bold;
        }

        .bg-colored .column-header-image {
            min-width: 60px;
            width: 100%;
        }
    }

    #discount-products-section-wrapper {
        margin: 0;

        .dimmer .content .icon {
            font-size: 5rem;
        }

        #discounts-discount-products-section {
            padding: 3rem 0;
        }
    }
}

#error-404-page {
    .error-404-header-panel {
        background-size: 100%;

        .text {
            font-size: 9rem !important;
        }

        .small-text {
            font-size: 4rem;
        }
    }

    .info-block {
        .content {
            font-size: 2rem;

            strong {
                font-size: 2.5rem;
            }
        }
    }
}

html.mobile-layout {
    .services {
        .services-background {
            min-height: 10rem;
            background-size: 200%;
            margin-bottom: 1rem;
        }

        .header.hero {
            line-height: 10rem;
            font-size: 3.5rem;
        }

        .page-content {
            .title {
                font-size: 2.5rem;
            }
        }

        .service-content {
            .header {
                min-height: auto;
                max-height: none;

                .title {
                    font-size: 2.5rem;
                    line-height: 5rem;
                }
            }
        }

        .sidebar {
            border-left: 0;
            margin-top: 3rem;

            .services-box-header {
                font-weight: bold;
            }
        }
    }
}

html.mobile-layout {
    .manufacturer-page {
        .manufacturer-image-wrapper {
            overflow: hidden;

            .image {
                max-width: 150%;
            }
        }

        .manufacturer-description {
            padding-top: 0;
        }
    }

    #manufacturer-treads-filter-menu {
        >.container {
            .title {
                font-size: 3rem;
                line-height: 3.5rem;
            }
        }

        .mainmenu,
        .submenu {
            height: 4rem;
            line-height: 3rem;
            font-size: 2rem;
            padding: 0.5rem 1rem;
        }

        .menu {
            .item {
                font-size: 2rem;
                line-height: 3rem;
                max-height: 4.5rem;

                .image {
                    min-width: 7rem;
                    max-width: 7rem;
                    height: 5rem;
                    min-height: 5rem;
                    max-height: 5rem;
                    padding: 0.5rem 1rem;

                    img {
                        height: 4rem;
                        max-height: 4rem;
                    }
                }
            }
        }

        .dropdown.icon {
            font-size: 1.5rem;
            line-height: 4rem;
            margin: 0;
            padding: 0;
            right: 2rem;
            top: 0;
        }
    }
}

html.mobile-layout {

    /* treads block */
    .content-tread {
        .description {
            max-height: 28rem;
            min-height: 28rem;
        }
    }
}

html.mobile-layout {
    .news-page {
        .ui.main.container {
            padding: 0 3rem 3rem;
            margin: 0 !important;

            .ui.card {
                width: auto;

                .content {
                    font-size: 2rem;
                }
            }
        }
    }

    .news-page {
        #content-news {
            article.main {
                padding: 0;

                >.ui.grid {
                    >.column {
                        padding: 0 3rem !important;

                        &.cover-image-simple {
                            padding: 0 !important;
                        }

                        &.body {
                            padding-top: 3rem !important;
                        }
                    }

                    .title {
                        .page-title-nice {
                            font-size: 3rem;
                            margin-top: 2rem !important;
                        }
                    }
                }
            }


            .sidebar {
                padding: 3rem 3rem 0;
                margin-top: 0 !important;
            }
        }
    }

    .custom-news-section {
        .ui.card {
            width: auto;
        }
    }

}

html.mobile-layout {
    .unique-contents-background {
        min-height: 15rem;
    }

    .unique-contents {
        .header.hero {
            line-height: 15rem;
            font-size: 6rem;
        }
    }

    .subcategories-menu {
        padding-top: 3rem;

        .column {
            a {
                height: 6rem;
                text-align: left;

                img {
                    display: inline-block;
                    width: 15%;
                    max-height: 2rem;
                    line-height: 2rem;
                }

                .title {
                    display: inline-block;
                    width: 80%;
                    font-size: 1.75rem;
                    padding-left: 1rem;
                    line-height: 2rem;
                }
            }

            &.label {
                .arrow {
                    height: 6rem;
                    margin-right: 1rem;

                    &:before {
                        border-width: 3rem 0 3rem 1rem;
                        right: -1.5rem;
                    }

                    &:after {
                        border-width: 3rem 0 3rem 1rem;
                        right: -1rem;
                    }
                }
            }
        }
    }
}

html.mobile-layout {
    .account-page {
        .user-profile-menu.menu {
            .item {
                padding: 0.5rem 1rem;
                float: left;
            }
        }

        .avatar {
            img.ui.image {
                width: auto;
                height: 10rem;
            }
        }
    }
}

html.mobile-layout {
    .my-cars {
        .car-image {
            max-width: 100%;
        }
    }
}

html.mobile-layout {
    .rim-search {
        .rim-results-section>.container {
            margin: 0 !important;
        }

        #rim-search-results {
            .search-results-panel {
                .rim-search-results {
                    padding-top: 0;
                }
            }
        }

        .car-image-section {
            .rim-container {

                &:after,
                &:before {
                    display: none;
                }

                .sizes {
                    font-size: 1.5rem;
                }
            }
        }

        .forms {
            .field-input-wrapper {
                .field-input {
                    border: 1px solid @marso-gray-10;
                }
            }
        }
    }
}

html.mobile-layout {
    .tab-menu-panel {
        padding-top: 3rem;

        .tab-menu-panel-header {
            >.column {
                border-left: 1px solid @white;

                a.button {
                    padding-top: 1rem;
                }

                .button {
                    padding: 0.5rem;
                    height: 5rem;

                    .icon {
                        display: inline-block;
                        opacity: 1;
                    }

                    .label {
                        display: inline-block;
                    }
                }

                .button.active {
                    box-shadow: 0 0 0 5px @marso-gray-10;

                    &:before {
                        bottom: -20px;
                    }

                    &:after {
                        bottom: -20px;
                    }
                }
            }
        }
    }
}

html.mobile-layout {
    .info-labels-wrapper {
        .info-label {
            .info-label-icon {
                .icon {}
            }

            .info-label-text {
                font-size: 1.5rem;
                line-height: 1.5rem;
            }
        }

        .info-label.big {
            .info-label-text {
                font-size: 1.5rem;
                line-height: 1.5rem;
                margin: 0.75rem 0 0.75rem 2.5rem;
            }
        }
    }
}

html.mobile-layout {
    .hs-frame {
        .hs-button-wrapper {
            width: 5rem;

            .hs-button {
                width: 5rem;
                height: 5rem;
            }
        }

        .hs-items {
            margin: 0 5rem;
        }
    }
}

html.mobile-layout {
    #goto-page-top {
        position: fixed;
        right: -100%;
        bottom: 5rem;
        padding: 0.5rem 3rem 0.5rem 0.5rem;
        height: 4rem;
        width: 6rem;
        font-size: 1rem;
        z-index: 99;
        will-change: right;
        transition: right 1s ease-out;

        &.active {
            right: -1rem;
        }
    }
}

html.mobile-layout {
    .products {
        &>.column {
            padding: 0 !important;
        }

        .product.teaser:hover {
            box-shadow: none !important;
        }
    }

    .product-main {
        .image-illustrative-notice {
            bottom: -4rem;
            white-space: nowrap;
            margin-right: 2rem;
        }

        .product-meta-wrapper {
            padding-top: 0;
        }

        .product-tyre-data-wrapper {
            padding-top: 1.5rem;
        }

        .product-cart-wrapper {
            .add-to-cart-button {}
        }
    }

    .video-box-embed {
        .video-container {
            position: relative;
            width: 100%;
            height: 0;
            padding-bottom: 56.25%;
        }
        .video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }


    .type-video,.type-image,.type-text {
        padding:0px !important;
    }

    .type-text {
        font-family:'Exo 2', 'Lato', Arial, Helvetica, sans-serif;
        font-size: 16px;
        color: rgba(0,0,0,1.0);
        display: flex !important;
        align-items: center;
        justify-content: flex-start;
        padding: 2rem !important;
    }

    .type-text-body {
        width: 100%;
        box-sizing: border-box;
        padding: 2rem;
    }

    .type-image-body {
        margin-bottom: -5px;
    }

    .type-image-body img {
        width: 100%;
    }

    .extra-content-container-wrapper {
        padding-top:2rem;
    }

    .extra-content-container {
        width:100%;
        background-color:#ececec;
        margin-bottom:1rem;
    }

    .main-grid {
        margin:0px !important;
    }

}


html.mobile-layout {
    .pager-wrapper {

        .total-column,
        .ordering-column,
        &>.column {
            border-radius: 0 !important;
        }

        .ordering-column {
            .ordering-select {
                left: 0;
            }
        }
    }
}

html.mobile-layout {
    .status-sold-out {
        .sold-out {
            font-size: 6rem;
        }

        &.teaser {
            .sold-out {
                font-size: 3.5rem;
            }
        }

        &.wide {
            .sold-out {
                font-size: 5rem;
            }
        }
    }
}

html.mobile-layout {

    #banners-header-line,
    #banners-info-line {
        .countdown-wrapper {
            flex-direction: column;
        }
    }
}

// newsletter
html.mobile-layout {

    #newsletter-page,
    #newsletter-subscribe-modal {
        .form-wrapper-panel {
            margin-top: 3rem;

            .floating-icon-wrapper {
                .floating-icon {
                    top: -6rem;
                }
            }

            .floating-title-wrapper {
                .floating-title {
                    left: 2rem;
                    top: -6.75rem;
                }
            }
        }
    }
}

#service-offer-request-page {

    margin: 0 -16px 0 -16px;


    // main
    h1 {
        font-size: 3rem;
    }

    .page-title {
        padding-top: 15px !important;
        padding-left: 8px;
    }

    // site
    .form-block .field .field-label-wrapper .field-label {
        font-size: 1.2rem;
    }

    .cls_frame {
        padding: 0px !important;
    }

    // data

    // file
    .cls_frame_file {
        padding: 0px 5px 0px 8px !important;
    }

    .cls_parent {
        position: relative;
        font-family: 'Exo 2', 'Lato', Arial, Helvetica, sans-serif;
        min-height: 50px;
    }

    #id_lbl_attachment {
        position: absolute;
        left: 0;
        top: 10px;
        width: auto;
        font-size: 2rem;
    }

    #id_input_file {
        position: absolute;
        right: 0;
        top: 0;
        width: 180px;
        height: 45px;

        height: 100%;
        cursor: pointer;
        opacity: 0;
    }

    #id_btn_addFiles {
        position: absolute;
        right: 0;
        top: 0;
        width: 48%;
    }

    #id_btn_deleteFiles {
        position: absolute;
        left: 0px;
        top: 0;
        width: 48%;
    }

    button[type="button"]:disabled {
        background-color: gray !important;
    }

    .ui.search.dropdown .menu {
        max-height: 20rem !important;
    }

}
